import { getCookie } from 'typescript-cookie';
import authService from '../auth/auth.service';

class Http {
  public static timeout = 30000 * 10; // 30 seconds

  private static getAuthHeaders = (): Headers => {
    const token = getCookie('token');
    let headers: any = {
      'Content-Type': 'application/json',
    };

    if (token?.length) headers.Authorization = `Bearer ${token}`;

    return new Headers(headers);
  };
  public static get = async <T>(path: string): Promise<T> => {
    const controller = new AbortController();
    const requestTimer = setTimeout(() => controller.abort(), this.timeout);
    controller.signal.addEventListener('abort', controller.abort);
    return await fetch(`${process.env.REACT_APP_API_URL}/${path}`, {
      method: 'get',
      signal: controller.signal,
      headers: this.getAuthHeaders(),
    }).then((res) => {
      if (res.status === 401) {
        authService.logout(true);
      }
      clearTimeout(requestTimer);
      controller.signal.removeEventListener('abort', controller.abort);
      return res?.json() as T;
    });
  };
  public static post = async <T>(path: string, body?: any): Promise<T> => {
    const controller = new AbortController();
    const requestTimer = setTimeout(() => controller.abort(), this.timeout);
    controller.signal.addEventListener('abort', controller.abort);
    return await fetch(`${process.env.REACT_APP_API_URL}/${path}`, {
      method: 'post',
      signal: controller.signal,
      body: body ? JSON.stringify(body) : undefined,
      headers: this.getAuthHeaders(),
    }).then((res) => {
      if (res.status === 401) {
        authService.logout(true);
      }
      clearTimeout(requestTimer);
      controller.signal.removeEventListener('abort', controller.abort);
      return res?.json() as T;
    });
  };
  public static put = async <T>(path: string, body?: any): Promise<T> => {
    const controller = new AbortController();
    const requestTimer = setTimeout(() => controller.abort(), this.timeout);
    controller.signal.addEventListener('abort', controller.abort);
    return await fetch(`${process.env.REACT_APP_API_URL}/${path}`, {
      method: 'PUT',
      signal: controller.signal,
      body: body ? JSON.stringify(body) : undefined,
      headers: this.getAuthHeaders(),
    }).then((res) => {
      if (res.status === 401) {
        authService.logout(true);
      }
      clearTimeout(requestTimer);
      controller.signal.removeEventListener('abort', controller.abort);
      return res?.json() as T;
    });
  };
  public static patch = async <T>(path: string, body?: any): Promise<T> => {
    const controller = new AbortController();
    const requestTimer = setTimeout(() => controller.abort(), this.timeout);
    controller.signal.addEventListener('abort', controller.abort);
    return await fetch(`${process.env.REACT_APP_API_URL}/${path}`, {
      method: 'PATCH',
      signal: controller.signal,
      body: body ? JSON.stringify(body) : undefined,
      headers: this.getAuthHeaders(),
    }).then((res) => {
      if (res.status === 401) {
        authService.logout(true);
      }
      clearTimeout(requestTimer);
      controller.signal.removeEventListener('abort', controller.abort);
      return res?.json() as T;
    });
  };

  public static delete = async <T>(path: string, body?: any): Promise<T> => {
    const controller = new AbortController();
    const requestTimer = setTimeout(() => controller.abort(), this.timeout);
    controller.signal.addEventListener('abort', controller.abort);
    return await fetch(`${process.env.REACT_APP_API_URL}/${path}`, {
      method: 'delete',
      signal: controller.signal,
      body: body ? JSON.stringify(body) : undefined,
      headers: this.getAuthHeaders(),
    }).then((res) => {
      if (res.status === 401) {
        authService.logout(true);
      }
      clearTimeout(requestTimer);
      controller.signal.removeEventListener('abort', controller.abort);
      return res?.json() as T;
    });
  };
}

export default Http;
