// RingCentral logo
import RingCentralLogo from '../images/ringcentral/ringcentral-logo-white.png';

const RingCentralWhiteLogo = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src={RingCentralLogo} alt={' Logo'} style={{ width: '70%' }} />
    </div>
  );
};

export default RingCentralWhiteLogo;
