// RingCentral logo
import RingCentralLogo from '../images/ringcentral/ringcentral-logo.png';

const RingCentral = () => {
  return (
    <div>
      <img
        src={RingCentralLogo}
        alt={' Logo'}
        style={{ width: '50%', marginBottom: '10px' }}
      />
    </div>
  );
};

export default RingCentral;
